import { Icon } from "@blueprintjs/core"
import React, { useEffect, useLayoutEffect, useState } from "react"
import { useGlobalFilter, useSortBy, useTable } from "react-table"

import styles from "./TableWithFilter.module.css"

import { track } from "~/helpers/analytics"
import { underscoreToReadable } from "~/helpers/general-helpers"
import { getElementHeight, mustGetElementHeight } from "~/helpers/layout-helper"

import Select from "~/common/Select"

import { ACTIVE_FILTER_OPTIONS } from "~/GLOBALVARS"
import GlobalFilter from "~/ReactTableCommon/GlobalFilter"
import { setSetting } from "~/localsettings"

// IMPORTANT NOTE:
// This component uses divs instead of table elements
// To allow for a sticky header

export type SortByProps = {
  sort: { id: string; desc: boolean }[]
  order: number[] | undefined
}

type Props = {
  type: string
  table: Record<string, any>
  existingFilter?: string
  existingSearch?: string
  gridTemplateColumns?: string
  showActiveFilterDropdown?: boolean
  disableRowClick?: boolean
  setListSortBy?: (value: SortByProps) => void
  setFilteredRows?: (value: any[]) => void
  customHandleRowClick?: (rowValues) => void
  buttons?: React.ReactNode
}

const TableWithFilter = (props: Props) => {
  const {
    existingSearch,
    gridTemplateColumns,
    table,
    showActiveFilterDropdown = true,
    disableRowClick,
    setListSortBy,
    setFilteredRows,
    customHandleRowClick,
    buttons,
  } = props
  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(table, useGlobalFilter, useSortBy)

  const [headerColumnsPositionFromTop, setHeaderColumnsPosition] = useState(0)

  const [activeFilter, setActiveFilter] = useState(
    ACTIVE_FILTER_OPTIONS.find(
      (a) => a.label.toLowerCase() === props.existingFilter?.toLowerCase(),
    ) || { value: true, label: "Active" },
  )

  useEffect(() => {
    if (existingSearch) {
      setGlobalFilter(existingSearch)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    setHeaderColumnsPosition(
      mustGetElementHeight("top-bar") + getElementHeight("page-header", 0),
    )
  }, [])

  useEffect(() => {
    const listOrder = {
      sort: state.sortBy,
      order: rows.map((r) => r.original.id),
    }
    const settingsMap = {
      projects: "projectListOrder",
      roles: "roleListOrder",
    }
    if (setListSortBy && settingsMap.hasOwnProperty(props.type)) {
      setSetting(settingsMap[props.type], listOrder)
      setListSortBy(listOrder)
    }
  }, [rows.length, state.sortBy]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleFilterChange = (newFilter) => {
    track(`Filter "${props.type}" Table`, {
      value: newFilter.label,
    })
    setActiveFilter(newFilter)
    history.replaceState({}, "", window.location.pathname)
  }

  const handleSearchChange = (newSearch) => {
    track(`Search "${props.type}" Table`)
    setGlobalFilter(newSearch)
    history.replaceState({}, "", window.location.pathname)
  }

  const filteredRows =
    showActiveFilterDropdown && activeFilter && activeFilter.label !== "All"
      ? rows.filter((row) => row.original.active === activeFilter.value)
      : rows

  useEffect(() => {
    if (setFilteredRows) {
      setFilteredRows(filteredRows)
    }
  }, [filteredRows.length]) // eslint-disable-line react-hooks/exhaustive-deps

  const numberOfColumns = headerGroups[0].headers.length
  const defaultGridTemplateColumns =
    gridTemplateColumns || `repeat(${numberOfColumns}, 1fr)`

  const onHandleCustomRowClick = (e, rowValues) => {
    if (customHandleRowClick) {
      e.preventDefault()
      customHandleRowClick(rowValues)
    }
  }

  return (
    <div data-test={`table-${props.type}`}>
      <div id="search-bar" className={styles.header}>
        <GlobalFilter
          globalFilter={state.globalFilter}
          setGlobalFilter={handleSearchChange}
        />
        {showActiveFilterDropdown && (
          <div className={styles.statusFilter}>
            <span>Filter</span>
            <Select
              name="activeFilter"
              id="activeFilter"
              value={activeFilter}
              onChange={handleFilterChange}
              options={ACTIVE_FILTER_OPTIONS}
              width="175px"
              isSearchable={false}
            />
          </div>
        )}
        {buttons && <div className={styles.buttons}>{buttons}</div>}
      </div>

      {!filteredRows.length ? (
        <div className={styles.noResults}>
          {`No ${
            showActiveFilterDropdown ? activeFilter.label.toLowerCase() : ""
          } ${underscoreToReadable(props.type)} found
           ${state.globalFilter ? `with "${state.globalFilter}"` : ""}`}
        </div>
      ) : (
        <div
          data-component="TableWithFilter/table"
          className={styles.table}
          {...getTableProps()}
        >
          {headerGroups.map((headerGroup) => (
            <div
              className={`${styles.headerRow} ${styles.row} ${styles.stickyWrapper}`}
              style={{
                top: headerColumnsPositionFromTop,
                gridTemplateColumns: defaultGridTemplateColumns,
              }}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <div
                  className={styles.headerColumn}
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({ title: undefined }),
                  )}
                >
                  {column.render("Header")}
                  {column.isSorted ? (
                    <Icon
                      className={styles.sortIcons}
                      icon={column.isSortedDesc ? "chevron-down" : "chevron-up"}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          ))}
          {filteredRows.map((row) => {
            prepareRow(row)

            if (disableRowClick) {
              return (
                <div
                  className={styles.row}
                  {...row.getRowProps()}
                  style={{ gridTemplateColumns: defaultGridTemplateColumns }}
                >
                  {row.cells.map((cell) => (
                    <div {...cell.getCellProps()}>{cell.render("Cell")}</div>
                  ))}
                </div>
              )
            }

            return (
              <a
                href={`/${props.type}/${row.original.hashid || ""}`}
                key={row.original.id}
                onClick={(e) => onHandleCustomRowClick(e, row.original)}
              >
                <div
                  className={styles.row}
                  {...row.getRowProps()}
                  style={{ gridTemplateColumns: defaultGridTemplateColumns }}
                >
                  {row.cells.map((cell) => (
                    <div
                      {...cell.getCellProps()}
                      className={
                        cell.column.id === "name" ? styles.nameCell : ""
                      }
                    >
                      {cell.render("Cell")}
                    </div>
                  ))}
                </div>
              </a>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TableWithFilter
